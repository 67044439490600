/**
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import $ from "jquery";
import Sortable from 'sortablejs';
window.TomSelect = require('@tabler/core/dist/libs/tom-select/dist/js/tom-select.complete.min.js');

document.addEventListener("DOMContentLoaded", function () {
    // Sortable.
    const sortableElements = document.getElementsByClassName('sortable'), sortableElLength = sortableElements.length;
    for (let i = 0; i < sortableElLength; i++) {
            new Sortable.create(sortableElements.item(i), {
            animation: 150,
            ghostClass: 'bg-rss-lt',
            chosenClass: 'bg-rss-lt',
            dragClass: 'bg-rss-lt',
            handle: '.js-action-sort-handle'
        });
    }

    // Tom-Select (single select).
    const selectElements = document.querySelectorAll('select.js-tom-select'), selectElLength = selectElements.length;
    for (let i = 0; i < selectElLength; i++) {
        console.log(selectElements.item(i));
        new TomSelect(selectElements.item(i),{
            maxItems: 1,
            copyClassesToDropdown: false,
            dropdownClass: 'dropdown-menu ts-dropdown',
            optionClass:'dropdown-item',
            controlInput: '<input>'
        });
    }

    // Tom-Select as Tags (select multiple).
    const selectTagsElements = document.querySelectorAll('select.js-tom-select--tags'), selectTagsElLength = selectTagsElements.length;
    for (let i = 0; i < selectTagsElLength; i++) {
        console.log(selectTagsElements.item(i));
        new TomSelect(selectTagsElements.item(i),{
            maxItems: null,
            copyClassesToDropdown: false,
            dropdownClass: 'dropdown-menu ts-dropdown',
            optionClass:'dropdown-item',
            controlInput: '<input>'
        });
    }

    // DatePicker.
    const elements = document.getElementsByClassName('datepicker'), elLength = elements.length;
    for (let i = 0; i < elLength; i++) {
        new Litepicker({
            element: elements.item(i),
            buttonText: {
                previousMonth: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="15 6 9 12 15 18" /></svg>',
                nextMonth: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="9 6 15 12 9 18" /></svg>',
            },
        })
    }

    // Conditional display triggers.
    conditionalDisplaySelect();
    conditionalDisplayCheckbox();
    conditionalHideCheckbox();

    // EmbedForm.
    $(document).ready(function () {
        // Get the div that holds the embed form.
        var $embedFormHolder = $('.embed-form');

        // count the current form inputs we have (e.g. 2), use that as the new index when inserting a new item (e.g. 2).
        $embedFormHolder.data('index', $embedFormHolder.find('input').length);

        // Trigger the AddEmbedForm function when a add_item_link from an Embed form is clicked.
        $('body').on('click', '.add_item_link', function (e) {
            var $embedHolderId = $('#'+$(e.currentTarget).data('embedHolderId'));
            // add a new embed form.
            addEmbedForm($embedHolderId);
        })
    });

    /**
     * Use the data-prototype attribute to dynamically add a new embed form.
     *
     * The data-prototype HTML contains the tag’s text input element with a name of and id.
     * The __name__ is a placeholder, which you’ll replace with a unique, incrementing number (e.g. task[tags][3][name]):
     *
     * @see https://symfony.com/doc/current/form/form_collections.html
     */
    function addEmbedForm($embedHolder) {
        // Get the data-prototype
        var prototype = $embedHolder.data('prototype');

        // Get the new index
        var index = $embedHolder.data('index');

        var newForm = prototype;

        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        newForm = newForm.replace(/__name__/g, index);

        // Increase the index with one for the next item.
        $embedHolder.data('index', index + 1);

        // Display the form in the page in an div, before the "Add" link button/link.
        var $newFormLi = $('<div class="js-prototype-form-add"></div>').append(newForm);

        // Add the new form at the end of the list.
        $embedHolder.append($newFormLi)
    }

    /**
     * Conditional display of div based on valid option on select.
     */
    function conditionalDisplaySelect() {
        // Get the select(s) that will trigger div visibility.
        const selectInputs = document.querySelectorAll(".toggleOnSelect");
        selectInputs.forEach(item => {
            item.addEventListener('change', function() {
                // For each select, get the data-target attribute to target the proper div to display/hide.
                const target = document.getElementById(this.getAttribute('data-target'))

                if (this.value) {
                    // When any value is pickup, then display the target div.
                    target.style.display = "block"

                } else {
                    // When no value is pickup, then hide the target div.
                    target.style.display = "none"
                }
            })
        });
    }

    /**
     * Conditional display of div based on checkbox.
     */
    function conditionalDisplayCheckbox() {
        // Get the checkbox(s) that will trigger div visibility.
        const checkboxInputs = document.querySelectorAll(".toggleShowWhenChecked");

        checkboxInputs.forEach(item => {
            item.addEventListener('change', function () {
                // For each checkbox, get the data-target attribute to target the proper div to display/hide.
                const target = document.getElementById(this.getAttribute('data-target'))

                if (this.checked) {
                    // When checked, then display the target div.
                    target.style.display = "block"

                } else {
                    // When unchecked, then hide the target div.
                    target.style.display = "none"
                }
            })
        });
    }

    /**
     * Conditional hide of div based on checkbox.
     */
    function conditionalHideCheckbox() {
        // Get the checkbox(s) that will trigger div visibility.
        const checkboxInputs = document.querySelectorAll(".toggleHideWhenChecked");

        checkboxInputs.forEach(item => {
            item.addEventListener('change', function () {
                // For each checkbox, get the data-target attribute to target the proper div to display/hide.
                const target = document.getElementById(this.getAttribute('data-target'))

                if (this.checked) {
                    // When checked, then hide the target div.
                    target.style.display = "none"

                } else {
                    // When unchecked, then display the target div.
                    target.style.display = "block"
                }
            })
        });
    }
});
